import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image, classes = 'rounded-sm' } = imageInfo;

  if (image && image.childImageSharp) {
    return (
      <Img className={classes} fluid={image.childImageSharp.fluid} alt={alt} />
    )
  }

  if (childImageSharp) {
    return <Img className={classes} fluid={childImageSharp.fluid} alt={alt} />
  }

  if (image)
    return <img className={classes} src={image} alt={alt} />

  return null
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  }).isRequired,
};

export default PreviewCompatibleImage;
